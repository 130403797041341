import React from 'react'
import styled from 'styled-components'

import WoodenBackgroundImg from 'images/backgrounds/wooden-background.jpeg'

const WoodenBackgroundStyles = styled.aside`
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
  height: 40rem;
  padding: 5rem 0;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  z-index: -1;
  &:before {
    position: absolute;
    top: 0rem;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: 2;
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
  }
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: 2;
    content: '';
    background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 1) 100%);
  }
`
const WoodenBackground = () => {
  const i = true
  return <WoodenBackgroundStyles img={WoodenBackgroundImg} />
}

export default WoodenBackground
