import GetImage from 'components/functional/getImage'
import MaxWidth from 'components/functional/maxWidth'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const SuspectsStyles = styled.section`
  width: 100%;
  padding: 2rem 0;
  text-align: center;
  @media ${media.md} {
    margin-top: 2rem;
  }
  h3 {
    font-size: 1.65rem;
    margin-bottom: 1rem;
    @media ${media.md} {
      font-size: 2.5rem;
    }
  }
  > p {
    max-width: 850px;
    margin: var(--auto);
    margin-top: 1.5rem;
  }
  .order {
    margin: 1rem auto;
    text-align: center;
    @media ${media.md} {
      font-size: 1.5rem;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    .suspect {
      .image {
        width: 80%;
        margin: var(--auto);
        max-width: 270px;
      }
      h4 {
        color: var(--main);
        margin: 1rem 0;
        text-transform: uppercase;
        @media ${media.md} {
          font-size: 1.5rem;
        }
      }
    }
    @media ${media.md} {
      max-width: 1200px;
      padding-bottom: 3rem;
      margin: 2rem auto 0 auto;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        'a a b b c c'
        'd d e e f f'
        'g g g i i i';
      /* grid-gap: 2.5rem 0.5rem; */

      .suspect {
        p {
          width: 90%;
          margin: var(--auto);
        }
        &:nth-child(1) {
          grid-area: a;
        }
        &:nth-child(2) {
          grid-area: b;
        }
        &:nth-child(3) {
          grid-area: c;
        }
        &:nth-child(4) {
          grid-area: d;
        }
        &:nth-child(5) {
          grid-area: e;
        }
        &:nth-child(6) {
          grid-area: f;
        }
        &:nth-child(7) {
          grid-area: g;
          width: 70%;
          margin: 0 0 0 30%;
        }
        &:nth-child(8) {
          grid-area: i;
          width: 70%;
          margin: 0 30% 0 0;
        }
      }
    }
  }
`
const Suspects = ({ data }) => (
  <MaxWidth $black>
    <SuspectsStyles>
      <h3>THE SUSPECTS</h3>
      <p>
        Agatha Christie’s script allows for incredible flexibility of
        interpretation and embodiment of each role. Every new cast member brings
        new depth to the production, and every night’s audience brings its own
        energy. As a result, each of the 28,000+ performances has been unique.
      </p>
      <p>
        But there are some things that remain unchanged in Christie’s clever
        characterisation...
      </p>
      <p className="order">IN ORDER OF APPEARANCE:</p>
      <div className="grid">
        {data.map((suspect, i) => (
          <div className="suspect" key={i}>
            <div className="image">
              <GetImage data={suspect.image.src} alt={suspect.image.alt} />
            </div>
            <h4>{suspect.name}</h4>
            <p>{suspect.description}</p>
          </div>
        ))}
      </div>
    </SuspectsStyles>
  </MaxWidth>
)

export default Suspects
