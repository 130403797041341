import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player/youtube'
import MaxWidth from 'components/functional/maxWidth'
import { media } from 'utils/Media'

const VideoWrapperStyles = styled.section`
  width: 100%;
  padding: 1.5rem 0;
  @media ${media.md} {
    max-width: 1000px;
    margin: 0 auto !important;
    text-align: center;
    padding: 2rem 0;
  }

  iframe {
    width: 100%;
    margin: 0 auto !important;
    height: auto;
    @media ${media.sm} {
      height: 22rem;
    }
    @media ${media.md} {
      height: 28rem;
      width: 750px;
    }
  }
`
const VideoWrapper = ({ videoCode }) => {
  const i = true
  return (
    <MaxWidth $black>
      <VideoWrapperStyles>
        <section>
          <ReactPlayer
            url={`https://youtu.be/${videoCode}`}
            width="100%"
            height="auto"
            playsinline
          />
        </section>
      </VideoWrapperStyles>
    </MaxWidth>
  )
}

export default VideoWrapper
