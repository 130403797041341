import MaxWidth from 'components/functional/maxWidth'
import WoodenBackground from 'components/shared/woodenBackground'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Icon from 'svgs/about-icon.svg'
import { media } from 'utils/Media'

const AboutIntroStyles = styled.section`
  width: 100%;
  position: relative;
  z-index: 2;
  section {
    padding: 3rem 0 2rem 0;
    width: 90%;
    max-width: var(--maxWidth);
    text-align: center;
    margin: var(--auto);
    position: relative;
    z-index: 5;
    svg {
      width: 100%;
      max-width: 400px;
      display: inline-block !important;
    }
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      margin-top: 2rem;
      font-size: 1.6rem;
      line-height: 2.5rem;
      @media ${media.md} {
        font-size: 2rem;
      }
      br {
        display: none;
        @media ${media.md} {
          display: block;
        }
      }
    }
    .logo {
      width: 100%;
      margin: 1.5rem auto;
      max-width: 250px;
      @media ${media.md} {
        max-width: 350px;
      }
    }
    p {
      max-width: 650px;
      margin: 0 auto 1rem auto;
    }
    h3 {
      font-size: 1.5rem;
      @media ${media.md} {
        font-size: 2rem;
      }
    }
  }
`
const AboutIntro = () => {
  const i = true
  return (
    <MaxWidth $wooden>
      <AboutIntroStyles>
        <section>
          <h1>ABOUT THE PLAY</h1>
          <h2>
            AGATHA CHRISTIE’S THE MOUSETRAP <br />
            is the world’s longest-running play.
          </h2>
          <div className="logo">
            <StaticImage
              src="../../images/logos/dont-see-it-solve-it.png"
              alt="Don't Just See It, Solve It | The  Mousetrap"
            />
          </div>
          <p>
            This thrilling West End production is THE genre-defining murder
            mystery from the best-selling novelist of all time… case closed!
          </p>
          <p>
            As news spreads of a murder in London, a group of seven strangers
            find themselves snowed in at a remote countryside guesthouse. When a
            police sergeant arrives, the guests discover – to their horror –
            that a killer is in their midst! One by one, the suspicious
            characters reveal their sordid pasts. Which one is the murderer? Who
            will be their next victim? Can you solve this world-famous mystery
            for yourself?
          </p>
          <p>
            For over 70 years, AGATHA CHRISTIE&rsquo;S THE MOUSETRAP has kept
            millions of people from every corner of the globe on the edge of
            their seats.
          </p>
          <h3>HAVE YOUDUNNIT?</h3>
        </section>
      </AboutIntroStyles>
    </MaxWidth>
  )
}

export default AboutIntro
