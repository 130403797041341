import AboutIntro from 'components/about/aboutIntro'
import Suspects from 'components/about/suspects'
import Layout from 'components/global/layout'
import PageHeader from 'components/shared/pageHeader'
import VideoWrapper from 'components/shared/videoWrapper'
import { graphql } from 'gatsby'
import React from 'react'

const About = ({ data }) => {
  const mob = data.mobHeaderImg
  const tab = data.tabletHeaderImg
  const desk = data.deskHeaderImg
  const suspectsData = data.Suspects.nodes
  const meta = {
    title: 'About | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <PageHeader mobileHeader={mob} tabletHeader={tab} desktopHeader={desk} />
      <AboutIntro />
      <VideoWrapper videoCode="o9Auk97FL7U" />
      <Suspects data={suspectsData} />
    </Layout>
  )
}

export default About

export const AboutQuery = graphql`
  query {
    mobHeaderImg: file(name: { eq: "3-mob" }) {
      childImageSharp {
        gatsbyImageData(
          width: 575
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    tabletHeaderImg: file(name: { eq: "3-tablet" }) {
      childImageSharp {
        gatsbyImageData(
          width: 991
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "3-desktop" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    Suspects: allSuspectsJson {
      nodes {
        name
        description
        image {
          alt
          src {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
